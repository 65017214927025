var videoModules = document.querySelectorAll('.full-width-video');

document.addEventListener('DOMContentLoaded', () => {

	videoModules.forEach(videoModule => {
		const videoObject = videoModule.querySelector('.full-width-video__upload');
		const videoEmbed = videoModule.querySelector('.full-width-video__embed');

		if (videoObject) { 
			const options = {
				root: null,
				rootMargin: '0px',
				threshold: 0.5
			};

			const callback = (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						if (videoObject.paused && videoObject.readyState >= 3) { // Check if the video can play
							videoObject.play().catch(error => {
								console.log('Play failed:', error);
							});
						}
					} else {
						videoObject.pause();
					}
				});
			};

			const observer = new IntersectionObserver(callback, options);

			observer.observe(videoObject);
		}
	});
});