// Hamburger/menu toggle
var mainMenu = document.querySelector('.site-header__main-menu');
var menuToggle = document.querySelector('.site-header__menu-toggle');
var hamburger = document.querySelector('.site-header__menu-toggle .hamburger');

menuToggle.onclick = function(e) {
    menuToggle.classList.toggle('site-header__menu-toggle--active');
    mainMenu.classList.toggle('site-header__main-menu--active');
    document.body.classList.toggle('main-menu-is-open');
    hamburger.classList.toggle('is-active')

    tl.reversed(!tl.reversed());
}


// Menu currency for custom pages/post types
var recipesParentPage = document.querySelector('#menu-item-339');
var postsParentPage = document.querySelector('#menu-item-358');

/*if ( document.body.classList.contains('single-recipe') ) {
    recipesParentPage.classList.add('current-menu-item')
}

if ( document.body.classList.contains('single-post') ) {
    postsParentPage.classList.add('current-menu-item')
}*/


// Dropdowns
document.addEventListener('DOMContentLoaded', () => {
    const dropDownMenus = document.querySelectorAll('.menu-item-has-children');

    dropDownMenus.forEach((dropDown) => {
        dropDown.addEventListener('click', (event) => {

            // Check if the clicked target is within a .sub-menu, if so, do nothing
            if (event.target.closest('.sub-menu')) {
                return;
            }

            event.preventDefault();

            // Prevent multiple dropdowns from opening at the same time
            dropDownMenus.forEach(menu => {
                if (menu !== dropDown) {
                    menu.classList.remove('open');
                }
            });

            dropDown.classList.toggle('open');

            event.stopPropagation();
        });
    });

    // Close all dropdowns when clicking anywhere outside the dropdowns
    document.addEventListener('click', (event) => {

        // Check if the clicked target is within a .sub-menu, if so, do nothing
        if (event.target.closest('.sub-menu')) {
            return;
        }

        dropDownMenus.forEach(menu => {
            menu.classList.remove('open');
        });
    });
});