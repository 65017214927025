import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function () {
    const fiftyfiftyContentCarouselOptions = {
        modules: [ Navigation ],
        centeredSlides: true,
        navigation: {
            nextEl: '.fifty-fifty-content-plus-carousel__btn-next',
            prevEl: '.fifty-fifty-content-plus-carousel__btn-prev',
        },

    };

    const fiftyfiftyContentCarouselSwiper = new Swiper('.fifty-fifty-content-plus-carousel-swiper', fiftyfiftyContentCarouselOptions);
});