const heroModule = document.querySelector('.hero');
const splitHeroModule = document.querySelector('.split-hero');
const body = document.querySelector('body');

document.addEventListener('DOMContentLoaded', function () {
	if (heroModule) {
		body.classList.add("has-hero");
	}

	if (splitHeroModule || body.classList.contains('single-post') || body.classList.contains('single-recipe')) {
		body.classList.add("has-split-hero");
	}
});